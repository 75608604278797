/************************************************
 *   :---:.                     .::::..          *
 *   :@@@@@%-               :+#@@@@@@@@@%*-      *
 *    :@@@@@@=            =@@@@@@@@@@@@@@@@@.    *
 *     -@@@@@@-          #@@@@@@@%***#@@@@=      *
 *      =@@@@@@.        #@@@@@%-       :=        *
 *       =@@@@@%.      #@@@@@#                   *
 *        +@@@@@%.    #@@@@@*                    *
 *         +@@@@@@.  %@@@@@*.                    *
 *           @@@@@%:%@@@@@*.%%.                  *
 *            @@@@@@@@@@@# %@@@+:     .=+.       *
 *            %@@@@@@@@@% #@@@@@@@%#%@@@@@*.     *
 *            .@@@@@@@@@. :*@@@@@@@@@@@@@@@#.    *
 *             :@@@@@@@:     :+#@@@@@@@%*-       *
 *              ......                           *
 *   ┌────────────────────────────────────────┐  *
 *   │         Vereenigde Compagnie           │  *
 *   │             fiveM server               │  *
 *   ├────────────────────────────────────────┤  *
 *   │                vcrp.nl                 │  *
 *   └────────────────────────────────────────┘  *
**************************************************
* Imports
**************************************************/
@import url('https://fonts.googleapis.com/css2?family=Inter:wght@100..900&display=swap');

/************************************************
* Default
*************************************************/
body,
html {
    font-family: "Inter", sans-serif;
    font-optical-sizing: auto;
    font-weight: 400;
    font-style: normal;
    font-variation-settings: "slnt" 0;
}

:root {
    --rgb-primary: 255, 127, 0;
    --bg-dark: rgb(27, 31, 40);
    --text-default: rgb(247, 247, 247);
    --color-primary: rgb(var(--rgb-primary));
    --text-primary: var(--color-primary);
    --bs-link-color-rgb: var(--rgb-primary);

    /* Bootstrap colors override */
    --bs-warning-bg-subtle: rgb(100, 95, 79);
    --bs-warning-text-emphasis: rgb(228 208 151);

    --loader-color: var(--color-primary);

    .admin {
        --bg-primary: rgb(242 246 249);
        --bg-dark: rgb(30 41 59);
        --text-dark: rgb(100 116 139);
    }
}

img {
    max-width: 100%;
}

ol, ul, menu {
    list-style: none;
    margin: 0;
    padding: 0;
}

li {
    display: list-item;
    text-align: -webkit-match-parent;
    unicode-bidi: isolate;
}

.no-gutters {
    margin: 0;
}

hr {
    margin-bottom: 0;
}

/************************************************
* Buttons (btn)
*************************************************/
.btn {
    display: flex;
    align-items: center;
    font-size: 0.75rem;
    line-height: 1rem;
    font-weight: 300;
    text-transform: uppercase;
    border-radius: .25em;

    i {
        font-size: 0.9em;
        padding-right: .25em;
    }

    &.btn-discord,
    &.btn-fivem {
        color: #fff;
        font-weight: 500;

        span {
            font-weight: 700;
            padding-left: .25em;
            padding-right: .25em;
        }
    }

    &.btn-discord {
        background: #7289da;
        &:hover {
            background: #4f609b;
        }
        &:active {
            background: #3f4b78;
        }
    }

    &.btn-fivem {
        background: #ff7002;
        &:hover {
            background: #d86d1b;
        }
        &:active {
            background: #d15b01;
        }
        img {
            height: 1em;
            margin-right: .5em;
        }
    }

    &.btn-danger{
        --loader-color: #fff;
    }

    &.btn-primary {
        --bs-btn-color: #fff;
        --bs-btn-bg: var(--color-primary);
        --bs-btn-border-color: var(--color-primary);
        --bs-btn-hover-color: #fff;
        --bs-btn-hover-bg: var(--color-primary);
        --bs-btn-hover-border-color: #0a58ca;
        --bs-btn-focus-shadow-rgb: 49, 132, 253;
        --bs-btn-active-color: #fff;
        --bs-btn-active-bg: var(--color-primary);;
        --bs-btn-active-border-color: var(--color-primary);
        --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
        --bs-btn-disabled-color: #fff;
        --bs-btn-disabled-bg: var(--color-primary);
        --bs-btn-disabled-border-color: var(--color-primary);
    }
}

/************************************************
* Cards (btn)
*************************************************/
.card {
    overflow: hidden;
    --bs-card-border-color: rgb(226 232 240);

    > h1 {
        position: absolute;
        display: flex;
        align-items: center;
        top: 0;
        left: 0;
        font-size: 1rem;
        line-height: 1.5rem;
        font-weight: 600;
        width: 100%;
        border-bottom: 1px solid rgb(226 232 240);
        height: 4rem;
        padding: 1rem;
        width: 100%;

        button {
            display: flex;
            position: absolute;
            right: 1rem;
            align-self: flex-end;
            background: white;
            border: 1px solid rgb(226 232 240);
            border-radius: 0.25rem;
            padding: 0.5rem;
            color: rgb(100 116 139);
            font-size: 0.875rem;
            box-shadow: 0 0 #0000, 0 0 #0000, 0 1px 3px 0 rgba(15, 23, 42, 0.07), 0 1px 2px 0 rgba(15, 23, 42, 0.03);
            transition: background 250ms cubic-bezier(0.4, 0, 0.2, 1);

            &:hover {
                background: rgb(241 245 249);
            }
        }
    }
    &:has(> h1){
        padding-top: 5rem !important;
    }

    .icon-bearer {
        background: var(--color-primary);
        font-size: 1.2em;
        border-radius: 100%;
        height: 3em;
        width: 3em;
        display: flex;
        justify-content: center;
        align-items: center;
        color: #fff;
        text-shadow: 1px 1px 2px rgba(0,0,0,0.5);
    }
}

/************************************************
* Widths
*************************************************/
.w-5  { width: 5%  !important;}
.w-10 { width: 10% !important;}
.w-15 { width: 15% !important;}
.w-20 { width: 20% !important;}
.w-30 { width: 30% !important;}
.w-35 { width: 35% !important;}
.w-40 { width: 40% !important;}
.w-45 { width: 45% !important;}
.w-55 { width: 55% !important;}
.w-60 { width: 60% !important;}
.w-65 { width: 65% !important;}
.w-70 { width: 70% !important;}
.w-80 { width: 80% !important;}
.w-85 { width: 85% !important;}
.w-90 { width: 90% !important;}
.w-95 { width: 95% !important;}

/************************************************
* flags
*************************************************/
.flag {
    display: flex;
    position: relative;
    width: 1.75em;
    height: 1em;
    border-radius: .15em;
    overflow: hidden;

    &:before,
    &:after {
        content: "";
        position: absolute;
        display: block;
    }

    &.flag-nl {
        background: white;

        &:before,
        &::after {
            width: 100%;
            height: 33%;
        }

        &:before {
            background: rgb(166, 2, 2);
            top: 0;
            left: 0;
        }

        &:after {
            background: rgb(36, 36, 168);
            bottom: 0;
            left: 0;
        }
    }

    &.flag-be {
        background: #FDDA24;

        &:before,
        &::after {
            width: 33%;
            height: 100%;
        }
        
        &:before {
            background: rgb(31, 31, 31);
            left: 0;
            top: 0;
        }

        &:after {
            background: #EF3340;
            right: 0;
            bottom: 0;
        }
    }
}

/************************************************
* Tables
*************************************************/
.default {
    .table {
        --bs-table-color: #fff;
        --bs-table-striped-color: #fff;
        --bs-table-bg: var(--bg-dark);
        --bs-table-striped-bg: rgba(255, 255, 255, 0.05);
        --bs-border-width: 0px;
    }
}

.admin {
    .table {
        thead {
            tr {
                th { 
                    padding-left: 1.5rem;
                    padding-right: 1.5rem;
                    padding-top: 0.75rem;
                    padding-bottom: 0.75rem;
                    text-align: start;
                    font-size: 0.75rem;
                    line-height: 1rem;
                    font-weight: 600;
                    text-transform: uppercase;
                }
            }
        }

        tbody {
            tr {
                td {
                    white-space: nowrap;
                    padding-left: 1.5rem;
                    padding-right: 1.5rem;
                    font-size: 0.875rem;
                    line-height: 1.25rem;
                    font-weight: 500;
                    vertical-align: middle;

                    > i {
                        font-size: 1.3em;
                    }
                }
            }
        }

        &.table-striped {
            tbody {
                tr {
                    &:nth-of-type(even) td {
                        --bs-table-bg-type: rgb(241 245 249);
                    }
                    &:nth-of-type(odd) * {
                        --bs-table-bg-type: white;
                    }

                    &:hover td {
                        --bs-table-bg-type: rgb(241 245 249);
                    }
                }
            }
            
        }
    }

    .ag-table,
    .ag-root-wrapper,
    .ag-root-wrapper-body,
    .ag-root {
        display: flex;
        height: 100%;
        width: 100%;
        border: 0px;
        border-radius: 0px;
    }

    .ag-root {
        .ag-header {
            height: 2em;
            background: transparent;
            border-top: 0px;
        }
    }

    .ag-table {
        .ag-cell {
            display: flex;
            align-items: center;
        }

        .ag-row {
            --ag-data-color: #4b5458;
        }

        .ag-paging-panel {
            border-top-color: transparent;
        }
        
        .ag-header-cell {
            height: auto !important;
            padding: 0;

            .ag-cell-label-container {
                position: relative;
                padding-left: 1.5rem;
                padding-right: 1.5rem;
                padding-top: 0.75rem;
                padding-bottom: 0.75rem;
                text-align: start;
                font-size: 0.75rem;
                line-height: 1rem;
                font-weight: 600;
                text-transform: uppercase;

                // .ag-icon {
                //     &:before {
                //         display: flex;
                //         justify-content: center;
                //         align-items: center;
                //         font-family: "Font Awesome 6 Pro";
                //         content: "\f0d8";
                //         opacity: 0;
                //         font-size: 1.25em;
                //         transition: transform 250ms ease-in-out, opacity 250ms ease-in-out;
                //     }
            
                //     &.ag-icon-desc:before {
                //         opacity: 1;
                //         transform: rotate(180deg);
                //         margin-left: 0.25em;
                //     }
            
                //     &.ag-icon-asc:before {
                //         padding-left: .25em;
                //         opacity: 1;
                //     }

                //     &.ag-icon-menu:before {
                //         content: '\f0b0';
                //     }
                // }
            }
        }

        .ag-cell {
            white-space: nowrap;
            padding-left: 1.5rem;
            padding-right: 1.5rem;
            font-size: 0.875rem;
            line-height: 1.25rem;
            font-weight: 400;
            vertical-align: middle;

            > i {
                font-size: 1.3em;
            }
        }

        .ag-row {
            white-space: nowrap;
            padding-left: 1.5rem;
            padding-right: 1.5rem;
            font-size: 0.875rem;
            line-height: 1.25rem;
            font-weight: 500;
        }

        .ag-row-even {
            background: rgb(241 245 249);   
        }
    }
}

/************************************************
* Loaders
*************************************************/
.loader {
    width: 50px;
    padding: 8px;
    aspect-ratio: 1;
    border-radius: 50%;
    background: var(--loader-color);
    --_m: 
      conic-gradient(#0000 10%,#000),
      linear-gradient(#000 0 0) content-box;
    -webkit-mask: var(--_m);
            mask: var(--_m);
    -webkit-mask-composite: source-out;
            mask-composite: subtract;
    animation: l3 1s infinite linear;
  }
  @keyframes l3 {to{transform: rotate(1turn)}}

/************************************************
* Panels (Publics)
*************************************************/
.panel {
    border: 1px solid #000;
    background: var(--bg-dark);
    border-radius: .15em;

    > h1,
    > h2,
    > h3,
    > h4,
    > h5 {
        background: rgba(0, 0, 0, 0.25);
        margin: 0;
        margin-block-start: 0;
        margin-block-end: 0;
        padding-bottom: .5em !important;
    }

    > hr {
        margin: 0;
        box-shadow: 0px 0px 0px rgba(0, 0, 0, 0.4), inset 0 1px 1px rgba(255, 255, 255, 0.1);
        border-top: 1px solid rgba(0, 0, 0, 0.5);
        height: 2px;
        opacity: 1;
    }

    &.shadow {
        box-shadow: 0 19px 38px rgba(0,0,0,0.30), 0 15px 12px rgba(0,0,0,0.22) !important;
    }
}

/************************************************
* Etc elements
*************************************************/
.emboss {
    box-shadow: 0px 0px 0px rgba(0, 0, 0, 0.4), inset 0 1px 1px rgba(255, 255, 255, 0.15);
}

.swatch {
    display: flex;
    width: 2em;
    height: 2em;
    margin-right: .25em;
    border-radius: .25em;
    justify-content: center;
    align-items: center;
    color: #fff;
    text-shadow: -1px 0 black, 0 1px black, 1px 0 black, 0 -1px black;
    user-select: none;
}

/************************************************
* Mobile classes
*************************************************/
@media (max-width: 576px) {
    .w-mobile-100 {
        width: 100%;
    }

    .btn.w-mobile-100 {
        justify-content: center;
    }

    .main-content .container {
        padding: 0;
    }

    .mobile-overflow {
        height: 100%;
        overflow-y: scroll;

        /* Edge and Firefox */
        -ms-overflow-style: none;
        scrollbar-width: none;

        &::-webkit-scrollbar {
            /* Chrome, Safari and Opera */
            display: none;
        }
    }
}

/************************************************
* Scrollbar
*************************************************/
body {
    /* Edge and Firefox */
    -ms-overflow-style: none;
    scrollbar-width: none;

    &::-webkit-scrollbar {
        /* Chrome, Safari and Opera */
        display: none;
    }
}